import { PageHeader } from 'components/page-header'

import OrdersTable from './OrdersTable'

const Orders = () => {
  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Orders' },
  ]

  return (
    <div>
      <PageHeader title="Orders" breadcrumbs={breadcrumbs} />
      <OrdersTable />
    </div>
  )
}

export default Orders
