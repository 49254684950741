import Basket from '../../Basket/Basket'
import TermsView from '../../Terms/View/TermsView'

import css from './Timeline.module.css'

const TimelineContent = ({ customer, item, status }) => {
  const { message, basket, terms } = item

  return (
    <div>
      <p className={css.pre}>{message}</p>
      {basket && <Basket basket={basket} label="Suggested products" />}
      {terms && <TermsView options={terms} />}
    </div>
  )
}

export default TimelineContent
