import { PageHeader } from 'components/page-header'

import OrdersTable from '../Orders/OrdersTable'

import css from './ListRequests.module.css'

const Requests = () => {
  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Requests and Orders' },
  ]

  return (
    <div className={css.page}>
      <PageHeader title="Requests and Orders" breadcrumbs={breadcrumbs} />

      <section>
        <h3>Orders</h3>
        <OrdersTable />
      </section>
    </div>
  )
}

export default Requests
