import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Text, Button } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'

import { usePdf } from 'common/hooks/usePdf'

import sales from 'api/sales'
import auth from 'api/auth'

import css from './PdfPreview.module.css'

const PdfPreview = ({ onUpdate, ...data }) => {
  const { orderId } = useParams()
  const [style, setStyle] = useState()
  const [pdfRef, setPdfRef] = useState()
  const { download, getCompiled, isLoading } = usePdf()

  const execute = (ref, data) => {
    data.me = auth.user
    const el = getCompiled(data, 'order')
    ref.replaceChildren(el)
  }

  const onSetRef = (ref) => {
    if (ref && !style) {
      setPdfRef(ref)

      execute(ref, data)

      const signatureHtml = document.getElementById('mySignature')
      if (signatureHtml) {
        const offsets = signatureHtml.getBoundingClientRect()
        const { top, left } = offsets
        setStyle({ top, left })
      }
    }
  }

  const onSign = async () => {
    const data = await onUpdate(() => sales.signOrder(orderId))

    execute(pdfRef, data)
  }

  return (
    <div className="print-page">
      <div className={css.layout}>
        <section>
          <div className="print-invisible">
            <div className={css.header}>
              <Button
                disabled={isLoading} //
                variant="default"
                leftSection={<IconDownload size={12} />}
                size="compact-sm"
                radius="sm"
                onClick={() => download(data, 'order', { title: 'Purchase order: #' + orderId })}
              >
                <Text size="xs">Download PDF</Text>
              </Button>
            </div>
          </div>

          <div className={css.page} ref={onSetRef} />

          {!data?.signatureBody && (
            <div className={css.signButton} style={style}>
              <Button onClick={onSign}>Sign</Button>
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default PdfPreview
