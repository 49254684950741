import { Text, NumberFormatter } from '@mantine/core'

import Table from 'components/Table/Table'

const columns = [
  {
    title: 'Product Code',
    render: ({ product: { productCode } }) => <small className="rounded outline w-600">{productCode}</small>,
  },
  { title: 'Description', id: 'product.description' },
  {
    title: 'Unit Price',
    render: ({ product: { sellingPrice, currency } }) => <NumberFormatter size="sm" prefix={`${currency} `} value={sellingPrice} thousandSeparator decimalScale={2} />,
  },
  { title: 'Quantity', id: 'quantity', textAlign: 'center' },
  {
    title: 'Total',
    textAlign: 'right',
    render: ({ product: { sellingPrice, currency }, quantity }) => (
      <NumberFormatter size="sm" prefix="$ " value={sellingPrice * quantity} thousandSeparator decimalScale={2} />
    ),
  },
]

const Basket = ({ basket, label }) => {
  if (basket) {
    let subTotal = 0

    basket?.forEach((item) => {
      item.total = item.quantity * item.product.sellingPrice
      subTotal += item.total
    })

    const tax = Math.ceil(subTotal * 0.15)
    const totalAmount = subTotal + tax

    return (
      <div>
        {label && <h4>{label}</h4>}
        <Table data={basket} columns={columns} footer={false}>
          <tbody>
            <tr>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                <Text size="sm" fw={500}>
                  Gross Amount
                </Text>
              </td>
              <td>
                <Text size="sm" fw={500}>
                  <NumberFormatter size="sm" prefix="$ " value={subTotal} thousandSeparator decimalScale={2} />
                </Text>
              </td>
            </tr>
            <tr>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                Tax 15%
              </td>
              <td>
                <NumberFormatter prefix="$ " size="sm" value={tax} thousandSeparator decimalScale={2} />
              </td>
            </tr>
            <tr>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                <Text size="sm" fw={500}>
                  Total Amount
                </Text>
              </td>
              <td>
                <Text size="sm" fw={500}>
                  <NumberFormatter size="sm" prefix="$ " value={totalAmount} thousandSeparator decimalScale={2} />
                </Text>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
}

export default Basket
