import { useState } from 'react'
import { Button, Text, Select } from '@mantine/core'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import sales from 'api/sales'

import css from './ExcelView.module.css'

const validate = {
  float: (value) => !isNaN(parseFloat(value)),
}

const ExcelView = ({ data, onSubmit, onClose }) => {
  const [selected, setSelected] = useState({})
  const [invalidCol, setInvalidCol] = useState({})
  const resolve = usePreload(sales.getProductLabels)

  // sorted items by value
  const items = (resolve.data || []).sort((a, b) => a.value.localeCompare(b.value))
  const itemsObj = items.reduce((buff, item) => {
    buff[item.value] = item
    return buff
  }, {})

  const required = items.filter(({ required }) => required)
  const optional = items.filter(({ required }) => !required)

  const list = data.slice(0, 15)
  const labels = Object.keys(data[0])

  const onSelect = (name, label) => {
    let newSelected

    if (name) {
      const labelObj = items.find(({ value }) => value === name)

      const validateFn = validate[labelObj.type]

      if (validateFn) {
        const totalInvalid = data.filter((row) => !validateFn(row[label]))
        setInvalidCol({ ...invalidCol, [label]: totalInvalid.length })
      }

      newSelected = { ...selected, [name]: label }
    } else {
      newSelected = Object.fromEntries(Object.entries(selected).filter(([n, v]) => v !== label))
      setInvalidCol({ ...invalidCol, [label]: false })
    }
    setSelected(newSelected)
  }

  const getItems = (items, currentLabel) => items.map(({ value, label }) => ({ value, label, disabled: selected[value] && selected[value] !== currentLabel }))

  const isDisabled = () => {
    const sel = Object.keys(selected)
    const hasInvalid = Object.values(invalidCol).find((v) => v)

    return !!required.find(({ value }) => !sel.includes(value)) || hasInvalid
  }

  return (
    <Preload {...resolve}>
      <div className={css.container}>
        <table className={css.table}>
          <thead>
            <tr>
              {labels.map((currentLabel, key) => (
                <th key={key}>
                  <Select
                    label={currentLabel}
                    searchable
                    error={invalidCol[currentLabel] && invalidCol[currentLabel] + ' invalid rows'}
                    placeholder="Select attribute"
                    onChange={(value) => onSelect(value, currentLabel)}
                    data={[
                      {
                        group: 'Mandatory',
                        items: getItems(required, currentLabel),
                      },
                      {
                        group: 'Optional',
                        items: getItems(optional, currentLabel),
                      },
                    ]}
                    maxDropdownHeight={400}
                    comboboxProps={{ withinPortal: false, position: 'bottom-start', shadow: 'md' }}
                    styles={{ dropdown: { width: '200px' } }}
                    size="xs"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map((item, key) => (
              <tr key={key}>
                {labels.map((label, i) => (
                  <td key={i} className={Object.values(selected).includes(label) ? css.selected : ''}>
                    {item[label]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Text p=".5rem" size="sm">
          Total: {data.length}
        </Text>
      </div>
      <div className="gap-05">
        <Button
          onClick={() => {
            const labels = {}
            for (let name in selected) {
              const value = selected[name]
              labels[value] = itemsObj[name]
            }
            onSubmit(labels)
          }} //
          size="sm"
          radius="sm"
          disabled={isDisabled()}
        >
          Upload
        </Button>
        <Button onClick={onClose} size="sm" radius="sm" variant="default">
          Close
        </Button>
      </div>
    </Preload>
  )
}

export default ExcelView
