import { PageHeader } from '../../components/page-header'
import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import Table from '../../components/Table/Table'
import { Avatar, NumberFormatter, Group, Button, Menu, Text, Stack } from '@mantine/core'
import {
  IconBuilding,
  IconMapPin,
  IconUsers,
  IconCalendar,
  IconChartBar,
  IconBrandTwitter,
  IconTags,
  IconBrandLinkedin,
  IconCoin,
  IconFileUpload,
  IconFileExport,
  IconFileSpreadsheet,
} from '@tabler/icons-react'
import { PiSealPercentThin } from 'react-icons/pi'
import { formatDistance } from 'date-fns'

import backend from '../../api/backend'

const Leads = () => {
  const resolve = usePreload(backend.getLeads)
  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Leads' }]

  const columns = [
    {
      title: 'Company',
      icon: <IconBuilding stroke={1} size={18} />,
      render: ({ logo, company }) => (
        <div className="flex">
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={logo} alt={`Logo ${company}`} />
          <b>{company}</b>
        </div>
      ),
    },
    { key: 'salesPipelineStage', title: 'Sales Stage', icon: <IconChartBar stroke={1} size={18} /> },
    {
      title: 'RFQs',
      icon: <PiSealPercentThin stroke={1} size={18} />,
      render: ({ rfqCount }) => <NumberFormatter value={rfqCount} thousandSeparator />,
    },
    {
      title: 'Last contact',
      icon: <IconCalendar stroke={1} size={18} />,
      render: ({ lastInteractionAt }) => {
        if (!lastInteractionAt) return ''
        return formatDistance(new Date(lastInteractionAt), new Date(), { addSuffix: true }).replaceAll('about', '').trim()
      },
    },
    { key: 'city', title: 'City', icon: <IconMapPin stroke={1} size={18} /> },
    { key: 'description', title: 'Description', ellipsis: '200px', icon: <IconTags stroke={1} size={18} /> },
    { key: 'employeeRange', title: 'Employees', icon: <IconUsers stroke={1} size={18} /> },
    { key: 'estimatedARR', title: 'Estimated ARR', icon: <IconCoin stroke={1} size={18} /> },
    {
      title: 'LinkedIn',
      icon: <IconBrandLinkedin stroke={1} size={18} />,
      render: ({ linkedinProfile }) =>
        linkedinProfile && (
          <a target="_blank" href={linkedinProfile} rel="noreferrer">
            {linkedinProfile.split('/').pop()}
          </a>
        ),
    },
    { key: 'twitter', title: 'Twitter', icon: <IconBrandTwitter stroke={1} size={18} /> },
    { key: 'industry', title: 'Categories', icon: <IconTags stroke={1} size={18} /> },
    {
      title: 'Known since',
      icon: <IconCalendar stroke={1} size={18} />,
      render: ({ createdAt }) => {
        if (!createdAt) return ''
        return formatDistance(new Date(createdAt), new Date(), { addSuffix: true }).replaceAll('about', '').trim()
      },
    },
    { key: 'foundationDate', title: 'Incorporation', icon: <IconCalendar stroke={1} size={18} /> },
    { id: 'webDomain', title: 'WebDomain' },
  ]

  const filter = {
    key: 'salesPipelineStage',
  }

  const handleImport = () => {
    // Implement import logic
    console.log('Importing leads...')
  }

  const handleExport = () => {
    // Implement export logic
    console.log('Exporting leads...')
  }

  const { data } = resolve
  return (
    <Preload {...resolve} className="container2">
      <PageHeader title="Leads" breadcrumbs={breadcrumbs} />

      <Table data={data} columns={columns} filter={filter} />
    </Preload>
  )
}

export default Leads
