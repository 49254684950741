import css from '../Thread.module.css'

const AddToBasket = ({ onPlus = (f) => f, onMinus = (f) => f, selected = {}, product = {}, canEdit }) => {
  return canEdit ? (
    <div className={css.quantity}>
      <button onClick={() => onMinus(product, -1)} disabled={!selected[product._key]}>
        -
      </button>
      <b onDoubleClick={() => console.log({ sel: selected[product._key], selected, product, canEdit })}>{selected[product._key] ?? 0}</b>
      <button onClick={() => onPlus(product)}>+</button>
    </div>
  ) : (
    product?.quantity ?? 0
  )
}

export default AddToBasket
