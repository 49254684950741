import { useState } from 'react'
import { Button, Timeline, Textarea, Avatar, ThemeIcon } from '@mantine/core'
import { GiElvenCastle } from 'react-icons/gi'

import orders from 'api/orders'

import css from './Thread.module.css'

import SuggestedProducts from './SuggestedProducts/SuggestedProducts'
import TermsForm from '../Terms/Form/TermsForm'

import TimelineTitle from './Timeline/TimelineTitle'
import TimelineContent from './Timeline/TimelineContent'

const Thread = ({ _key, thread, customer, account, basket, type, canEdit, view, onUpdate }) => {
  const { searchResults } = thread[0]
  const company = { account, customer }
  const isEnquiry = type === 'enquiry'

  const initialSelected = {}
  const products =
    basket?.map(({ product, quantity }) => {
      initialSelected[product._key] = quantity
      return product
    }) ||
    searchResults?.reduce((buf, { suggestedProducts, quantity }) => {
      if (suggestedProducts[0]) {
        initialSelected[suggestedProducts[0]._key] = quantity
        return [...buf, ...suggestedProducts]
      }
      return buf
    }, [])

  const [selected, setSelected] = useState(initialSelected)
  const [message, setMessage] = useState()
  const [terms, setTerms] = useState()

  const onSubmit = () => {
    setMessage('')
    let fn = () => orders.reply(_key, { selected, message, terms })

    if (view) {
      fn = () => orders.customerReply(_key, { message })
    }

    onUpdate(fn)
  }

  const onCreateOrder = () => {
    setMessage('')
    onUpdate(() => orders.createOrder(_key, { selected, message, terms }))
  }

  const onAddToBasket = ({ _key }, count = 1) => {
    console.log('-- add to basket --', { _key, count })
    let total = selected[_key] || 0
    total += count

    selected[_key] = total

    if (total <= 0) {
      delete selected[_key]
    }

    setSelected({ ...selected })
  }

  return (
    <div className={css.section}>
      <Timeline active={thread.length} bulletSize={40} lineWidth={1}>
        {thread.map((item, key) => (
          <Timeline.Item
            bullet={
              <ThemeIcon variant="white" radius={'xl'} size={38}>
                <Avatar radius="xl" src={company[item.from]?.logo} alt={`Logo ${company[item.from]?.name}`} size={32} />
              </ThemeIcon>
            }
            title={<TimelineTitle customer={customer} account={account} item={item} />}
            key={`ti-${key}`}
          >
            <TimelineContent item={item} customer={customer} />
          </Timeline.Item>
        ))}

        {view ? (
          <Timeline.Item bullet={<GiElvenCastle style={{ width: 24, height: 24 }} />} title="Customer reply">
            <div>
              <Textarea autosize resize="both" value={message} onChange={({ target }) => setMessage(target.value)} minRows={3} w={600} />
            </div>

            <div className={css.actions}>
              <Button onClick={() => onSubmit()}>Submit</Button>
            </div>
          </Timeline.Item>
        ) : (
          <Timeline.Item bullet={<GiElvenCastle style={{ width: 24, height: 24 }} />} title="My reply">
            {isEnquiry && (
              <div>
                <SuggestedProducts products={products} selected={selected} onAddToBasket={onAddToBasket} />
                <TermsForm onChange={setTerms} />
              </div>
            )}

            <div>
              <Textarea autosize resize="both" value={message} onChange={({ target }) => setMessage(target.value)} minRows={3} w={600} />
            </div>

            <div className="gap-05">
              <Button onClick={onSubmit}>Submit</Button>

              {isEnquiry && (
                <Button color="green" onClick={onCreateOrder}>
                  Create order
                </Button>
              )}
            </div>
          </Timeline.Item>
        )}
      </Timeline>
    </div>
  )
}

export default Thread
