import React, { useState } from 'react'
import { formatDistance } from 'date-fns'

import { FcGoogle } from 'react-icons/fc'
import { Container, Accordion, Text, Button, Group, Stack, TextInput, List, FileInput, Card } from '@mantine/core'
import { IconFileExport, IconPackage, IconMail, IconSeeding, IconFileTypeXls, IconPlus, IconTrash, IconApi } from '@tabler/icons-react'
import ApiKey from './ApiKey/ApiKey'
import ProductsSettings from './Products/Products'
import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import { PageHeader } from '../../components/page-header'
import './Settings.css'

import backend from 'api/backend'
import sales from 'api/sales'

const Settings = () => {
  const [emailAddresses, setEmailAddresses] = useState([])
  const [newEmail, setNewEmail] = useState('')
  const [fileLeads, setFileLeads] = useState(null)

  const resolveProducts = usePreload()
  const resolveLeads = usePreload()

  const resolveAccountInfo = usePreload(sales.getMyAccount)

  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Settings' }]

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const addEmail = () => {
    if (validateEmail(newEmail) && emailAddresses.length < 3 && !emailAddresses.includes(newEmail)) {
      setEmailAddresses([...emailAddresses, newEmail])
      setNewEmail('')
    }
  }

  const removeEmail = (email) => {
    setEmailAddresses(emailAddresses.filter((e) => e !== email))
  }

  const { settings, edge, productCount } = resolveAccountInfo.data || {}
  return (
    <Container size="xl" className="SettingsPage">
      <PageHeader title="Settings" breadcrumbs={breadcrumbs} />

      <Accordion multiple defaultValue={['products', 'leads', 'email', 'connectGmail']}>
        <Accordion.Item value="products">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconPackage size={28} stroke={1} />
              <div>
                <Text size="sm">Manage Products</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Sync your product inventory and adjust other product related settings.
                </Text>
              </div>
            </Group>
          </Accordion.Control>

          <Accordion.Panel>
            <ProductsSettings productCount={productCount} deleteBtn={true} exportBtn={true} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="leads">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconSeeding size={28} stroke={1} />
              <div>
                <Text size="sm">Manage Leads</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Sync your product inventory and adjust other product related settings.
                </Text>
              </div>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack spacing="xs">
              <Group position="apart" align="flex-end">
                <FileInput
                  size="sm"
                  w={300}
                  leftSection={<IconFileTypeXls stroke={1} size={18} />}
                  label="Attach your XLSX file"
                  placeholder="XLSX file"
                  leftSectionPointerEvents="none"
                  onChange={({ target }) => {
                    setFileLeads(target.files[0])
                  }}
                />
                <Button type="submit" variant="default" size="sm" radius="sm">
                  Upload
                </Button>

                <Button variant="default" leftSection={<IconFileExport size={12} />} size="sm" radius="sm">
                  <Text size="xs">Export Leads</Text>
                </Button>
              </Group>
              <Preload {...resolveProducts} className="container2">
                {resolveLeads.data && resolveLeads.data.leads && (
                  <Text size="xs" color="dimmed">
                    Leads imported: <b>{resolveLeads.leads.length}</b>
                  </Text>
                )}
              </Preload>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="connectGmail">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconMail size={28} stroke={1} />
              <Stack>
                <Text size="sm"> Gmail Integration</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Sync your Gmail Inbox to read incoming request messages and send emails.
                </Text>
              </Stack>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Preload {...resolveAccountInfo}>
              <Stack spacing="xs">
                <Stack spacing="xs" gap="0">
                  <Text size="xs" fw={500}>
                    Select your Google Mail account
                  </Text>
                  <Text size="xs" c="dimmed">
                    Select the Gmail account you use to receive quotes, orders and inbound messages from your users. We'll use the same account to respond to client queries
                    and send follow up documentation.
                  </Text>
                </Stack>
                <Group align="center" justify="space-around">
                  <Button variant="default" size="sm" radius="sm" leftSection={<FcGoogle size={12} stroke={1} />} onClick={() => backend.initiateGAuthForGmail()}>
                    Connect your Gmail
                  </Button>
                  {settings && settings.gmailAuth ? (
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                      <Group>
                        <Stack gap={0}>
                          <FcGoogle size={32} stroke={1} />
                          <Text size="xs" c="dimmed">
                            OAuth
                          </Text>
                        </Stack>
                        <Stack spacing="sm" gap="0">
                          <Text size="xs" fw={500}>
                            {settings.gmailAuth.emailAccount}
                          </Text>
                          <Text size="xs" fw={500} c="green.8">
                            Connected
                          </Text>
                          <Text size="xs" c="dimmed">
                            {formatDistance(new Date(settings.gmailAuth.lastModified), new Date(), { addSuffix: true })}
                          </Text>
                        </Stack>
                      </Group>
                    </Card>
                  ) : null}
                </Group>
              </Stack>
            </Preload>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="apiKey">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconApi size={28} stroke={1} />
              <div>
                <Text size="sm">Manage ApiKey</Text>
                <Text size="xs" c="dimmed" fw={400}>
                  Create API key.
                </Text>
              </div>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack spacing="xs">
              <ApiKey edge={edge} />
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
}

export default Settings
