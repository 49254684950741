import settings from 'api/settings'

import css from './TermsView.module.css'

const TermsView = ({ options }) => {
  if (options) {
    return (
      <div>
        <h4>Delivery, payment and terms</h4>
        <div className={css.terms}>
          <table>
            <tbody>
              {Object.entries(options).map(([name, value]) => {
                const { description, selected } = settings.get(name, value)
                return (
                  <tr key={name}>
                    <td>
                      <b>{description}</b>
                    </td>
                    <td>
                      <div>{selected?.label}</div>
                      <div className={css.desc}>{selected?.description}</div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default TermsView
