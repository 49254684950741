import { Link } from 'react-router-dom'
import { Avatar, Button } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import dayjs from 'dayjs'

import { PageHeader } from 'components/page-header'
import { Preload, usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import admin from 'api/admin'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Admin', href: '/admin' },
  { label: 'Accounts' },
]

const AdminAccounts = () => {
  const resolve = usePreload(admin.getAccounts)
  const { data } = resolve

  const columns = [
    {
      title: 'Logo',
      render: ({ logo, name }) => (
        <Avatar color="cyan" radius="sm" src={logo}>
          {name.split(' ').map(([v]) => v.toUpperCase())}
        </Avatar>
      ),
    },
    {
      title: 'Company name',
      id: 'name',
    },
    {
      title: 'User count',
      id: 'users.length',
    },
    {
      title: 'HQ Location',
      id: 'hqLocation',
    },
    {
      title: 'Web Domain',
      id: 'webDomain',
    },
    {
      textAlign: 'right',
      render: ({ _key }) => (
        <Button component={Link} to={`/admin/accounts/${_key}`} leftSection={<IconEye size="1rem" stroke={1} />} variant="subtle" color="green" size="xs">
          view
        </Button>
      ),
    },
  ]

  return (
    <div>
      <PageHeader title="All accounts" breadcrumbs={breadcrumbs} />

      <Preload {...resolve}>
        <Table data={data} columns={columns} />
      </Preload>
    </div>
  )
}

export default AdminAccounts
