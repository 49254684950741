import { formatRelative } from 'date-fns'
import { Avatar, Anchor, Group } from '@mantine/core'

import { PageHeader } from 'components/page-header'
import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import backend from 'api/backend'

const Customers = () => {
  const resolve = usePreload(backend.getCustomers)
  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Customers' }]

  const columns = [
    {
      title: 'Company',
      render: ({ logo, name, website }) => (
        <Group>
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={logo} alt={`Logo ${name}`} />
          <Anchor href={`${website}`} target="_blank">
            {name}
          </Anchor>
        </Group>
      ),
    },
    { title: 'About', id: 'shortDescription' },
    { title: 'Created at', render: ({ createdAt }) => formatRelative(createdAt, new Date()) },
    { title: 'Representatives', textAlign: 'center', id: 'representatives.length' },
    { title: 'Enquiries', textAlign: 'center', id: 'enquiries.length' },
    { title: 'Orders', textAlign: 'center', id: 'orders.length' },
  ]

  const filter = {
    key: 'enquiries.length',
  }

  const { data } = resolve
  return (
    <Preload {...resolve}>
      <PageHeader title="Customers" breadcrumbs={breadcrumbs} />
      <Table data={data} columns={columns} filter={filter} />
    </Preload>
  )
}

export default Customers
