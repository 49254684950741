import { client } from './http'

class OrdersApi {
  createEnquiry = (body) => client.post('/sales/enquiries/local', body)
  createOrder = (id, body) => client.post(`/sales/orders/${id}/create`, body)

  reply = (id, body) => client.put(`/sales/orders/${id}/reply`, body)
  customerReply = (id, body) => client.put(`/sales/orders/${id}/customer-reply`, body)

  deleteOrder = (id) => client.delete(`/sales/orders/${id}`)
}

const orders = new OrdersApi()
export default orders
