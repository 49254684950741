import { useParams } from 'react-router-dom'
import { Tabs } from '@mantine/core'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import { PageHeader } from 'components/page-header'

import CompanyAside from 'components/CompanyAside/CompanyAside'

import StatusSections from 'components/StatusSections/StatusSections'

import sales from 'api/sales'

import css from './OrderPage.module.css'

import Basket from './Basket/Basket'
import TermsView from './Terms/View/TermsView'

import Thread from './Thread/Thread'
import PdfPreview from './PdfPreview/PdfPreview'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Requests and Orders', href: '/o2c/requests-for-quote' },
  { label: 'Order' },
]

const statusList = [
  { status: 'initiated', label: 'Enquiry' },
  { status: 'updated', label: 'Reply sent' },
  { status: 'orderCreated', label: 'PO created' },
  { status: 'signed', label: 'Signed' },
  { status: 'shipping', label: 'Shipping' },
  { status: 'completed', label: 'Completed' },
]

//---------------------------------------------------------------------------
const OrderPage = () => {
  const { orderId } = useParams()

  const resolve = usePreload(() => sales.getOrder(orderId))
  const { data, onUpdate } = resolve
  const { basket, terms, status, type } = data || {}

  const isOrder = type === 'order'
  const activeTab = isOrder ? 'order' : 'thread'

  return (
    <div>
      <PageHeader title={'Order Preview - ID' + orderId} breadcrumbs={breadcrumbs} />
      <Preload {...resolve}>
        <div className={css.layout}>
          <aside>
            <CompanyAside {...data} />
          </aside>

          <section>
            <div className={css.header}>
              <StatusSections list={statusList} status={status || 'draft'} />
            </div>

            <div className={css.content}>
              <Tabs keepMounted={false} color="teal" defaultValue={activeTab}>
                <Tabs.List>
                  {isOrder && <Tabs.Tab value="order">Order</Tabs.Tab>}
                  <Tabs.Tab value="thread">Thread</Tabs.Tab>
                  {isOrder && <Tabs.Tab value="pdf">Documents</Tabs.Tab>}
                  <Tabs.Tab value="customer">
                    <div className={css.tab}>
                      Customer<div className={css.demo}>Demo</div>
                    </div>
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="order">
                  <Basket basket={basket} label="Order" />

                  <TermsView options={terms} />
                </Tabs.Panel>
                <Tabs.Panel value="thread">
                  <Thread {...data} onUpdate={onUpdate} />
                </Tabs.Panel>

                <Tabs.Panel value="pdf">
                  <PdfPreview {...data} onUpdate={onUpdate} />
                </Tabs.Panel>

                <Tabs.Panel value="customer">
                  <Thread {...data} view="customer" onUpdate={onUpdate} />
                </Tabs.Panel>
              </Tabs>
            </div>
          </section>
        </div>
      </Preload>
    </div>
  )
}

export default OrderPage
