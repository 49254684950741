import { useState } from 'react'
import { Button, Tooltip, Timeline, Textarea, Group, Text, Avatar, ThemeIcon, Stack, NumberFormatter, List } from '@mantine/core'
import { GiElvenCastle } from 'react-icons/gi'
import { RiArrowDropRightFill } from 'react-icons/ri'
import { formatDistance } from 'date-fns'
import RFQAttributesForm from 'components/RFQAttributesForm/RFQAttributesForm'
import Table from '../Table/Table'
import Modal from '../Modal/Modal'
import settings from 'api/settings'

import css from './Conversations.module.css'

import AddToBasket from './AddToBasket'
import ProductsTable from '../../screens/Products/ProductsTable'

const unpackRFQAttributes = (rfqOpts) => {
  if (!rfqOpts || Object.values(rfqOpts).length <= 0) return null

  return Object.entries(rfqOpts).map(([k, v]) => {
    const attribute = { ...settings.requestCategories().rfqAttributes[k] }
    const valueItem = attribute.options.filter((o) => o.value === v)[0]
    return { value: valueItem, term: { ...attribute, options: undefined } }
  })
}

const buildRFQAttributesDisplay = (rfqOpts) => {
  if (!rfqOpts || Object.values(rfqOpts).length <= 0) return null
  const unpacked = unpackRFQAttributes(rfqOpts)
  return (
    <>
      <Text size="sm" c="gray.7" fw={500}>
        Terms of the offer
      </Text>
      <List spacing={0} size="xs" center withPadding icon="-">
        {Object.values(unpacked).map((opt, i) => (
          <List.Item key={`rfqattli-${i}`}>
            <Group gap="sm">
              <Text size="sm" c="gray.7">
                {opt.term.description}
              </Text>
              <ThemeIcon variant="white" color="teal" size={24} radius="xl">
                <RiArrowDropRightFill style={{ width: 24, height: 24 }} />
              </ThemeIcon>
              <Text size="sm" c="gray.7" fw={400}>
                {opt.value.label}
              </Text>
              <Text size="sm" c="gray.7" fw={300}>
                ({opt.value.description})
              </Text>
            </Group>
          </List.Item>
        ))}
      </List>
    </>
  )
}

//---------------------------------------------------------------------------
const Conversations = ({ enquiry, canEdit, onCreateOrder, onSubmit }) => {
  const initialProducts = enquiry.basket?.map(({ product }) => product)

  const initialSelected = enquiry.basket?.reduce((buf, item) => {
    buf[item.product._key] = item
    return buf
  }, {})

  const [products, setProducts] = useState(initialProducts)
  const [message, setMessage] = useState()
  const [selected, setSelected] = useState(initialSelected)
  const [rfqAttributes, setRfqAttributes] = useState({})

  const handleFormChange = (formValues) => {
    setRfqAttributes(formValues)
  }
  const [showModal, setShowModal] = useState()

  const onAddToBasket = (product, count = 1) => {
    const item = selected[product._key] || { product, quantity: 0 }
    item.quantity += count

    selected[product._key] = item
    if (item.quantity <= 0) {
      delete selected[product._key]
    }
    const newSelected = { ...selected }
    setSelected(newSelected)
  }

  const columns = (canEdit) => [
    {
      title: 'Product Code',
      render: ({ productCode }) => <small className="rounded outline w-600">{productCode}</small>,
    },
    {
      title: 'Brand',
      key: 'brand',
    },
    { title: 'Description', key: 'description' },
    {
      title: 'Discount',
      render: ({ discount, currency }) => <NumberFormatter prefix={`${currency} `} value={discount} thousandSeparator decimalScale={2} fixedDecimalScale={false} />,
    },
    {
      title: 'Retail Price',
      render: ({ sellingPrice, currency }) => <NumberFormatter prefix={`${currency} `} value={sellingPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />,
    },
    {
      title: 'Quantity',
      render: (product) => (
        <AddToBasket
          onPlus={onAddToBasket} //
          onMinus={onAddToBasket}
          selected={selected}
          product={product}
          canEdit={canEdit}
        />
      ),
    },
    {
      title: 'Total',
      render: ({ sellingPrice, quantity, currency, _key }) => {
        const q = selected[_key] ? selected[_key].quantity : quantity
        return <NumberFormatter prefix={`${currency} `} value={sellingPrice * q} thousandSeparator decimalScale={2} />
      },
    },
  ]

  const { customer, createdBy } = enquiry

  return (
    <div className={css.section}>
      <Timeline active={1} bulletSize={40} lineWidth={1}>
        {enquiry.conversation.map((item, key) => (
          <Timeline.Item
            bullet={
              item.type === 'client' ? (
                <ThemeIcon variant="white" radius={'xl'} size={38}>
                  <Avatar radius="xl" src={customer.logo} alt={`Logo ${customer.name}`} size={32} />
                </ThemeIcon>
              ) : (
                <GiElvenCastle style={{ width: 24, height: 24 }} />
              )
            }
            title={
              item.type === 'client' ? (
                <Stack gap="xs">
                  <Group>
                    <Text c="gray.8">{customer.name}</Text>
                    <Tooltip label={createdBy.email}>
                      <Text size="sm">
                        {createdBy.firstName} {createdBy.lastName}
                      </Text>
                    </Tooltip>
                  </Group>
                  <time>{formatDistance(item.createdAt, new Date())} ago</time>
                </Stack>
              ) : (
                <Stack gap="xs">
                  Company
                  <time>{formatDistance(item.createdAt, new Date())} ago</time>
                </Stack>
              )
            }
            key={`ti-${key}`}
          >
            <p className={css.pre}>{item.message}</p>
            {item.basket && (
              <>
                <Table
                  data={item.basket.map(({ product, quantity }) => ({ ...product, quantity }))} //
                  columns={columns()}
                />
                {buildRFQAttributesDisplay(item.rfqOptions)}
              </>
            )}
          </Timeline.Item>
        ))}

        {onSubmit && (
          <Timeline.Item bullet={<GiElvenCastle style={{ width: 24, height: 24 }} />} title="My reply">
            {enquiry.basket && canEdit && (
              <Stack>
                <div className={css.subHeader}>
                  <h4>Suggested products</h4>
                  <Button variant="light" onClick={() => setShowModal(JSON.stringify(selected))}>
                    Add new product
                  </Button>
                </div>
                <Table data={products} columns={columns(true)} displayExtendedMenu={false} />
                <RFQAttributesForm onFormChange={handleFormChange} />
              </Stack>
            )}
            <div className={css.section}>
              <Textarea autosize resize="both" minRows={3} value={message} w={600} onChange={({ target }) => setMessage(target.value)} />
            </div>

            <div className={css.actions}>
              <Button onClick={() => onSubmit(message, selected, rfqAttributes)}>Submit</Button>
              {onCreateOrder && (
                <Button color="green" disabled={!Object.keys(selected).length} onClick={() => onCreateOrder(message, selected, rfqAttributes)}>
                  Create order
                </Button>
              )}
            </div>
          </Timeline.Item>
        )}
      </Timeline>

      {showModal && (
        <Modal>
          <ProductsTable selected={selected} onAddToBasket={onAddToBasket} limit={15} />
          <div className={css.modalActions}>
            <Button
              variant="outline"
              onClick={() => {
                setSelected(JSON.parse(showModal))
                setShowModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                const products = Object.values(selected).map(({ product }) => product)
                setProducts(products)
                setShowModal(false)
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Conversations
