import { Tooltip, Group, Text, Stack, Badge } from '@mantine/core'
import { formatDistance } from 'date-fns'

const statusColors = {
  inProgress: 'blue',
  pending: 'gray',
  orderCreated: 'orange',
  completed: 'green',
  initiated: 'green',
}

const TimelineTitle = ({ customer, account, item }) => {
  const { createdBy, createdAt, status, from } = item
  const company = from === 'customer' ? customer : account

  return (
    <Stack gap="xs">
      <Group>
        <Text c="gray.8">{company.name}</Text>
        <Tooltip label={createdBy.email}>
          <Text size="sm">
            {createdBy.firstName} {createdBy.lastName}
          </Text>
        </Tooltip>
      </Group>
      <div className="flex">
        <Badge size="sm" color={statusColors[status]}>
          {status}
        </Badge>
        <time>{formatDistance(createdAt, new Date())} ago</time>
      </div>
    </Stack>
  )
}

export default TimelineTitle
