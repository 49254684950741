import { useState } from 'react'
import { Button, NumberFormatter } from '@mantine/core'

import Table from 'components/Table/Table'
import Modal from 'components/Modal/Modal'

import ProductsTable from 'screens/Products/ProductsTable'

import AddToBasket from './AddToBasket'

const SuggestedProducts = ({ products = [], selected, onAddToBasket, canEdit = true }) => {
  // const [prods, setProds] = useState(products)
  const [showModal, setShowModal] = useState()

  const setSelected = () => {}
  const setProducts = () => {}

  const columns = (canEdit) => [
    {
      title: 'Product Code',
      render: ({ productCode }) => <small className="rounded outline w-600">{productCode}</small>,
    },
    { title: 'Name', key: 'productName' },
    { title: 'Description', key: 'description' },
    {
      title: 'Retail Price',
      render: ({ sellingPrice, currency }) => <NumberFormatter prefix={`${currency} `} value={sellingPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />,
    },
    {
      title: 'Quantity',
      render: (product) => (
        <AddToBasket
          onPlus={onAddToBasket} //
          onMinus={onAddToBasket}
          selected={selected}
          product={product}
          canEdit={canEdit}
        />
      ),
    },
    {
      title: 'Total',
      render: ({ sellingPrice, quantity, currency, _key }) => {
        const q = selected[_key] ? selected[_key] : quantity
        return <NumberFormatter prefix={`${currency} `} value={sellingPrice * q} thousandSeparator decimalScale={2} />
      },
    },
  ]

  return (
    <div>
      <div className="flex-1">
        <h4>Suggested products</h4>
        <Button variant="light" onClick={() => setShowModal(JSON.stringify(selected))}>
          Add new product
        </Button>
      </div>
      <Table data={products} columns={columns(true)} displayExtendedMenu={false} />

      {showModal && (
        <Modal>
          <ProductsTable selected={selected} onAddToBasket={onAddToBasket} limit={15} />
          <div className="gap-05">
            <Button
              variant="outline"
              onClick={() => {
                setSelected(JSON.parse(showModal))
                setShowModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                const products = Object.values(selected).map(({ product }) => product)
                setProducts(products)
                setShowModal(false)
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default SuggestedProducts
