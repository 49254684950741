import { useState } from 'react'
import { Stack, FileInput, Button, Text, Group } from '@mantine/core'
import { IconFileTypeXls, IconFileExport } from '@tabler/icons-react'

import readXlsxFile from 'read-excel-file'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Modal from 'components/Modal/Modal'
import sales from 'api/sales'

import ExcelView from './ExcelView/ExcelView'

const acceptedFiles = (...types) => {
  const fileTypes = {
    json: 'application/json',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
  }

  return Object.entries(fileTypes)
    .filter(([name, type]) => types.includes(name))
    .map(([name, type]) => type)
    .join(',')
}

//-----------------------------------------------------------------------------
const ProductsSettings = ({ deleteBtn, exportBtn, productCount, onCompleted = () => {} }) => {
  const [file, setFile] = useState(null)
  const [data, setData] = useState(null)
  const resolveProducts = usePreload()

  //---------------------------------------------------------------------------
  const submitProducts = async (e) => {
    e.preventDefault()

    if (file.type === 'application/json') {
      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result)

          if (Array.isArray(json)) {
            setData(json)
          } else {
            console.error(json)
            alert('Incorrect data format!')
          }
        } catch (error) {
          console.error('Error parsing JSON:', error)
        }
      }
      reader.readAsText(file)
    } else {
      const rows = await readXlsxFile(file)

      const [labels, ...rest] = rows

      const json = rest.map((arr) => {
        const item = {}
        labels.forEach((name, index) => (item[name] = arr[index]))
        return item
      })

      setData(json)
    }
  }

  //---------------------------------------------------------------------------
  const onSubmit = async (labels) => {
    setData(null)
    const result = await resolveProducts.onUpdate(() => sales.uploadProducts(file, labels))
    onCompleted(result)
  }

  //---------------------------------------------------------------------------
  const onDeleteProducts = () => {
    sales.deleteProducts()
  }

  const totalProducts = resolveProducts.data?.products?.length || productCount

  //---------------------------------------------------------------------------
  return (
    <div>
      <Stack spacing="xs">
        <form onSubmit={submitProducts}>
          <Group align="flex-end">
            <FileInput
              size="sm"
              w={300}
              accept={acceptedFiles('json', 'xlsx')}
              leftSection={<IconFileTypeXls stroke={1} size={18} />}
              label="Attach your XLSX file"
              placeholder="XLSX file"
              leftSectionPointerEvents="none"
              onChange={setFile}
            />
            <Button type="submit" variant="default" size="sm" radius="sm" disabled={!file}>
              Upload
            </Button>
            {exportBtn && (
              <Button variant="default" leftSection={<IconFileExport size={12} />} size="sm" radius="sm">
                <Text size="xs">Export Products</Text>
              </Button>
            )}
          </Group>
        </form>
        <Preload {...resolveProducts} className="container2">
          {!!totalProducts && (
            <Text size="xs" color="dimmed">
              Total products imported : <b>{totalProducts}</b>
            </Text>
          )}
        </Preload>
      </Stack>

      {deleteBtn && (
        <Button variant="light" color="red" size="xs" label="Delete" onClick={onDeleteProducts}>
          Delete
        </Button>
      )}

      {data && (
        <Modal>
          <ExcelView data={data} onSubmit={onSubmit} onClose={() => setData(null)} />
        </Modal>
      )}
    </div>
  )
}

export default ProductsSettings
