import { Link } from 'react-router-dom'

import { IconTrashX } from '@tabler/icons-react'
import { ActionIcon, Badge, Avatar, NumberFormatter } from '@mantine/core'
import { formatDistance } from 'date-fns'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import sales from 'api/sales'
import orders from 'api/orders'

const statusColors = {
  inProgress: 'blue',
  pending: 'gray',
  orderCreated: 'orange',
  completed: 'green',
  initiated: 'green',
}

const OrdersTable = () => {
  const resolve = usePreload(sales.getOrders)
  const { data, onRetry } = resolve

  const columns = [
    {
      title: 'Company',
      render: ({ customer: { logo, companyName, name } }) => (
        <div className="flex">
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={logo} alt={`Logo ${companyName || name}`} />
          <b>{companyName || name}</b>
        </div>
      ),
    },
    {
      title: 'Rep',
      render: ({ thread: [{ createdBy } = {}] = [] }) => {
        return (
          createdBy && (
            <div>
              <b>
                {createdBy.firstName} {createdBy.lastName}
              </b>
              <div>
                <small>{createdBy.email}</small>
              </div>
            </div>
          )
        )
      },
    },
    {
      title: 'Created at',
      render: ({ createdAt }) => formatDistance(new Date(createdAt), new Date(), { addSuffix: true }),
    },
    {
      title: 'Updated at',
      render: ({ updatedAt }) => updatedAt && formatDistance(new Date(updatedAt), new Date(), { addSuffix: true }),
    },
    {
      title: 'Type',
      textAlign: 'center',
      id: 'type',
    },
    {
      title: 'Status',
      textAlign: 'center',
      render: ({ status }) =>
        status && (
          <Badge size="sm" color={statusColors[status]}>
            {status}
          </Badge>
        ),
    },
    {
      title: 'Items in basket',
      textAlign: 'center',
      id: 'basket.length',
    },
    {
      title: 'Total order value',
      textAlign: 'right',
      render: ({ basket }) => {
        if (basket) {
          const totalValue = basket.map((bi) => bi.product.sellingPrice * bi.quantity).reduce((res, el) => res + el, 0)
          return <NumberFormatter prefix="$ " value={totalValue} thousandSeparator decimalScale={2} />
        }
      },
    },
    { render: ({ _key }) => <Link to={`/o2c/orders/${_key}`}>Open</Link> },
    {
      render: ({ _key }, { showConfirm }) => (
        <ActionIcon
          variant="light" //
          color="red"
          onClick={() => showConfirm(() => onRetry(() => orders.deleteOrder(_key)))}
        >
          <IconTrashX size={18} stroke={1.5} />
        </ActionIcon>
      ),
    },
  ]

  const filter = {
    key: 'status',
  }
  return (
    <Preload {...resolve}>
      <Table data={data} columns={columns} filter={filter} />
    </Preload>
  )
}

export default OrdersTable
